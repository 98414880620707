import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class Attachment {
  attachmentRegion?: Maybe<Array<AttachmentRegion>>;
  attachmentRegionsDDD?: Maybe<Array<AttachmentRegionDdd>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlFile?: Maybe<Scalars['String']>;
};

export class AttachmentDddInput {
  dddId: Scalars['String'];
};

export class AttachmentPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Attachment>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class AttachmentRegion {
  attachment: Attachment;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
};

export class AttachmentRegionDdd {
  attachment: Attachment;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regionDdd?: Maybe<RegionDdd>;
  updatedAt: Scalars['DateTime'];
};

export class AttachmentRegionInput {
  regionId: Scalars['String'];
};

export class Attachments {
  _id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export class AuthInput {
  email: Scalars['String'];
  password: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Banner {
  bannerRegion?: Maybe<Array<BannerRegion>>;
  bannerRegionsDDD?: Maybe<Array<BannerRegionDdd>>;
  bannerRoleGroup?: Maybe<Array<BannerRoleGroup>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
};

export class BannerDddInput {
  dddId: Scalars['String'];
};

export class BannerPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Banner>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class BannerRegion {
  banner: Banner;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
};

export class BannerRegionDdd {
  banner: Banner;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regionDdd?: Maybe<RegionDdd>;
  updatedAt: Scalars['DateTime'];
};

export class BannerRegionInput {
  regionId: Scalars['String'];
};

export class BannerRoleGroup {
  banner: Banner;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  roleGroupId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class BannerRoleGroupInput {
  roleGroupId: Scalars['String'];
};

export class ChangePasswordInput {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export class ChannelRoleGroups {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class ChannelsModel {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<ChannelRoleGroups>>;
  subChannels?: Maybe<Array<SubChannels>>;
};

export class ColorPalette {
  colorClass: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hexColor1: Scalars['String'];
  hexColor2: Scalars['String'];
  hexColor3: Scalars['String'];
  id: Scalars['ID'];
  offerGroupFlyerB5?: Maybe<Array<OfferGroupFlyer>>;
  offerGroupFlyerB6?: Maybe<Array<OfferGroupFlyer>>;
  offerGroupFlyerB7?: Maybe<Array<OfferGroupFlyer>>;
  offerGroups?: Maybe<Array<OfferGroup>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class ColorPaletteDto {
  colorClass: Scalars['String'];
  hexColor1: Scalars['String'];
  hexColor2: Scalars['String'];
  hexColor3: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export class Conversion {
  conversionGuide?: Maybe<Array<ConversionGuide>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroups?: Maybe<Array<OfferGroup>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlOfferMapConversionImage?: Maybe<Scalars['String']>;
};

export class ConversionGuide {
  conversion: Conversion;
  conversionGuideOpportunities?: Maybe<Array<ConversionGuideOpportunities>>;
  createdAt: Scalars['DateTime'];
  customerArrived: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  finished: Scalars['Boolean'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlCustomerArrivedIcon?: Maybe<Scalars['String']>;
  urlOfferMapConversionGuideImage?: Maybe<Scalars['String']>;
  urlWhatToDoIcon?: Maybe<Scalars['String']>;
  whatToDo: Scalars['String'];
};

export class ConversionGuideOpportunities {
  conversionGuide: ConversionGuide;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  urlDescriptionIcon?: Maybe<Scalars['String']>;
  urlDescriptionImage?: Maybe<Scalars['String']>;
};

export class ConversionGuidePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<ConversionGuide>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ConversionPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Conversion>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Coverage {
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fiveG: Scalars['Boolean'];
  id: Scalars['ID'];
  internet: Scalars['Boolean'];
  state: State;
  ultraFibra: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export class CoveragePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Coverage>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CreateAttachmentInput {
  DDDs?: InputMaybe<Array<AttachmentDddInput>>;
  regions?: InputMaybe<Array<AttachmentRegionInput>>;
  title: Scalars['String'];
};

export class CreateBannerInput {
  DDDs?: InputMaybe<Array<BannerDddInput>>;
  end: Scalars['DateTime'];
  link?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<BannerRegionInput>>;
  roleGroups?: InputMaybe<Array<BannerRoleGroupInput>>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateConversionGuideInput {
  conversionId: Scalars['String'];
  customerArrived: Scalars['String'];
  title: Scalars['String'];
  whatToDo: Scalars['String'];
};

export class CreateConversionGuideOpportunityInput {
  conversionGuideId: Scalars['String'];
  customerArrived: Scalars['String'];
  description: Scalars['String'];
  title: Scalars['String'];
  whatToDo: Scalars['String'];
};

export class CreateConversionInput {
  title: Scalars['String'];
};

export class CreateCoverageInput {
  city: Scalars['String'];
  fiveG: Scalars['Boolean'];
  internet: Scalars['Boolean'];
  stateId: Scalars['String'];
  ultraFibra: Scalars['Boolean'];
};

export class CreateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export class CreateFavoriteInformativeInput {
  informativeId?: InputMaybe<Scalars['String']>;
};

export class CreateFavoriteInput {
  offerGroupId?: InputMaybe<Scalars['String']>;
};

export class CreateHighlightInput {
  DDDs?: InputMaybe<Array<HighlightDddInput>>;
  end: Scalars['DateTime'];
  regions?: InputMaybe<Array<HighlightRegionInput>>;
  roleGroups?: InputMaybe<Array<HighLightRoleGroupInput>>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateHighlightStoryInput {
  highlightId: Scalars['String'];
};

export class CreateMigrationGuideInput {
  title: Scalars['String'];
};

export class CreateOfferGroupCommandmentsInput {
  description: Scalars['String'];
  number: Scalars['Float'];
  offerGroupId: Scalars['String'];
};

export class CreateOfferGroupExtraInput {
  channels?: InputMaybe<Array<OfferGroupChannelInput>>;
  colorPaletteId: Scalars['String'];
  conversionId?: InputMaybe<Scalars['String']>;
  descriptive?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  migrationGuideId?: InputMaybe<Scalars['String']>;
  offerGroupId: Scalars['String'];
  offerSegmentId: Scalars['String'];
  offerTypeId: Scalars['String'];
  portabilityRules?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<OfferGroupRegionInput>>;
  serviceChannels?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updated?: InputMaybe<Scalars['DateTime']>;
  validity?: InputMaybe<Scalars['DateTime']>;
};

export class CreateOfferGroupFlyerInput {
  DDDs?: InputMaybe<Array<OfferGroupFlyerDddInput>>;
  advantages?: InputMaybe<Array<OfferGroupFlyerAdvantagesInput>>;
  b1Ate?: InputMaybe<Scalars['Boolean']>;
  b1BonusDoPlano?: InputMaybe<Scalars['String']>;
  b1Download?: InputMaybe<Scalars['String']>;
  b1Plano?: InputMaybe<Scalars['String']>;
  b1Quantidade?: InputMaybe<Scalars['String']>;
  b1Subtitulo?: InputMaybe<Scalars['String']>;
  b1Titulo?: InputMaybe<Scalars['String']>;
  b1Upload?: InputMaybe<Scalars['String']>;
  b2InternetAcumulada?: InputMaybe<Scalars['String']>;
  b2RegrasRedeSocial?: InputMaybe<Scalars['String']>;
  b2Titulo?: InputMaybe<Scalars['String']>;
  b2_1Rodape?: InputMaybe<Scalars['String']>;
  b2_1Separador?: InputMaybe<Scalars['String']>;
  b2_5InternetNoAviao?: InputMaybe<Scalars['Boolean']>;
  b2_5Roaming?: InputMaybe<Scalars['String']>;
  b2_5RoamingBonus?: InputMaybe<Scalars['String']>;
  b2_5RoamingBonusDescritivo?: InputMaybe<Scalars['String']>;
  b2_5RoamingPlus?: InputMaybe<Scalars['Boolean']>;
  b2_5RoamingQtd1?: InputMaybe<Scalars['String']>;
  b2_5RoamingQtd2?: InputMaybe<Scalars['String']>;
  b2_5RoamingText1?: InputMaybe<Scalars['String']>;
  b2_5RoamingText2?: InputMaybe<Scalars['String']>;
  b2_6Descritivo?: InputMaybe<Scalars['String']>;
  b2_6Modalidades?: InputMaybe<Scalars['String']>;
  b3InternetAcumulada?: InputMaybe<Scalars['String']>;
  b3RegrasRedeSocial?: InputMaybe<Scalars['String']>;
  b3Titulo?: InputMaybe<Scalars['String']>;
  b4_1FidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_1FidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_1FidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_1FidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_1NaoFidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_1NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_1NaoFidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_1NaoFidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_1NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_1Titulo?: InputMaybe<Scalars['String']>;
  b4_2FidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_2FidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_2FidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_2FidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_2NaoFidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_2NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_2NaoFidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_2NaoFidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_2NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_2Titulo?: InputMaybe<Scalars['String']>;
  b4_3CreditoLivreValorCredito?: InputMaybe<Scalars['Float']>;
  b4_3NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_3NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_3PrecoValorDia?: InputMaybe<Scalars['Float']>;
  b4_3ValorRecarga?: InputMaybe<Scalars['Float']>;
  b5AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b5AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b5AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b5AparelhoPlano?: InputMaybe<Scalars['String']>;
  b5AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b5AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b5AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b5DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b5Icon5G?: InputMaybe<Scalars['Boolean']>;
  b5IconSIM?: InputMaybe<Scalars['Boolean']>;
  b5Parcelamento?: InputMaybe<Scalars['String']>;
  b5Separador?: InputMaybe<Scalars['String']>;
  b5Titulo?: InputMaybe<Scalars['String']>;
  b5colorPaletteId?: InputMaybe<Scalars['String']>;
  b6AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b6AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b6AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b6AparelhoPlano?: InputMaybe<Scalars['String']>;
  b6AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b6AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b6AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b6DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b6Icon5G?: InputMaybe<Scalars['Boolean']>;
  b6IconSIM?: InputMaybe<Scalars['Boolean']>;
  b6Parcelamento?: InputMaybe<Scalars['String']>;
  b6Separador?: InputMaybe<Scalars['String']>;
  b6Titulo?: InputMaybe<Scalars['String']>;
  b6colorPaletteId?: InputMaybe<Scalars['String']>;
  b7AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b7AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b7AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b7AparelhoPlano?: InputMaybe<Scalars['String']>;
  b7AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b7AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b7AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b7DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b7Icon5G?: InputMaybe<Scalars['Boolean']>;
  b7IconSIM?: InputMaybe<Scalars['Boolean']>;
  b7Parcelamento?: InputMaybe<Scalars['String']>;
  b7Titulo?: InputMaybe<Scalars['String']>;
  b7colorPaletteId?: InputMaybe<Scalars['String']>;
  dataFinal?: InputMaybe<Scalars['DateTime']>;
  dataInicial?: InputMaybe<Scalars['DateTime']>;
  novaOferta?: InputMaybe<Scalars['Boolean']>;
  ofertaFoco?: InputMaybe<Scalars['Boolean']>;
  offerGroupId?: InputMaybe<Scalars['String']>;
  premiumBenefits?: InputMaybe<Array<OfferGroupFlyerPremiumBenefitInput>>;
  socialNetworks?: InputMaybe<Array<OfferGroupFlyerSocialNetworksInput>>;
  status?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<Array<OfferGroupFlyerSubscriptionsInput>>;
};

export class CreateOfferGroupFlyerResponse {
  id: Scalars['ID'];
};

export class CreateOfferGroupInput {
  channels?: InputMaybe<Array<OfferGroupChannelInput>>;
  colorPaletteId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  offerSegmentId: Scalars['String'];
  offerTypeId: Scalars['String'];
  regions?: InputMaybe<Array<OfferGroupRegionInput>>;
  title: Scalars['String'];
  updated?: InputMaybe<Scalars['DateTime']>;
  validity?: InputMaybe<Scalars['DateTime']>;
};

export class CreateOfferMapInput {
  fixCommandmentsOfferGroup?: InputMaybe<Scalars['String']>;
  fixConversionOfferGroup?: InputMaybe<Scalars['String']>;
  fixDetailOfferGroup?: InputMaybe<Scalars['String']>;
  fixMigrationOfferGroup?: InputMaybe<Scalars['String']>;
  offerGroupFlyerId?: InputMaybe<Scalars['String']>;
  offerGroupId: Scalars['String'];
};

export class CreatePartnerAppInput {
  description?: InputMaybe<Scalars['String']>;
  howToActivate?: InputMaybe<Scalars['String']>;
  includedCombo?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  validity?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export class CreateUserInput {
  email: Scalars['String'];
  jobRole?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  profile: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class DuplicateOfferGroupFlyerResponse {
  id: Scalars['ID'];
};

export class EmailMaterial {
  attachments?: Maybe<Array<Attachments>>;
};

export enum EnumMaterialKind {
  Communication = 'communication',
  CommunicationBackup = 'communication_backup',
  CompaniesMaterials = 'companies_materials',
  ComunicacaoParaOCanal = 'comunicacao_para_o_canal',
  DevicesComparator = 'devices_comparator',
  Document = 'document',
  LinksUteis = 'links_uteis',
  MateriaisParaOPdv = 'materiais_para_o_pdv',
  Material = 'material',
  Matinais = 'matinais',
  News = 'news',
  OfferMap = 'offer_map',
  PartsMap = 'parts_map',
  PdvMap = 'pdv_map',
  PdvPieces = 'pdv_pieces',
  Pilulas = 'pilulas',
  Planogram = 'planogram',
  PocketGuide = 'pocket_guide',
  PriceTable = 'price_table',
  Process = 'process',
  Regulation = 'regulation',
  SalesArgument = 'sales_argument',
  SeeMoreChannel = 'see_more_channel',
  SeeMoreRegion = 'see_more_region',
  SocialFeed = 'social_feed',
  SupportMaterials = 'support_materials',
  Tanamao = 'tanamao',
  Video = 'video'
}

export class EnumType {
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export class FavoriteInformative {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  informativeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userTimId?: Maybe<Scalars['String']>;
};

export class FavoriteOffer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroup?: Maybe<OfferGroup>;
  updatedAt: Scalars['DateTime'];
  userTimId?: Maybe<Scalars['String']>;
};

export class File {
  signedUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export class FileMaterial {
  _id?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export class FilterFindManyMaterialInput {
  filter?: InputMaybe<FindManyMaterial>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  sort: SortFindManyMaterialInput;
};

export class FindManyMaterial {
  inAvailableAtRegions?: InputMaybe<Array<Scalars['String']>>;
  kind: EnumMaterialKind;
  title?: InputMaybe<Scalars['String']>;
  validityEnum: ValidityEnum;
};

export class FlyerColorPaletteDto {
  colorClass: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hexColor1: Scalars['String'];
  hexColor2: Scalars['String'];
  hexColor3: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class FlyerOfferGroupDto {
  colorPalette?: Maybe<FlyerColorPaletteDto>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  descriptive?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offerGroupFlyer?: Maybe<Array<FlyerOfferGroupFlyerDto>>;
  offerGroupRegion?: Maybe<Array<FlyerOfferGroupRegionDto>>;
  offerType?: Maybe<FlyerOfferTypeDto>;
  portabilityRules?: Maybe<Scalars['String']>;
  serviceChannels?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  urlBackgroundImage?: Maybe<Scalars['String']>;
  urlBannerImage?: Maybe<Scalars['String']>;
  urlBonusImage?: Maybe<Scalars['String']>;
  urlDescriptiveGeneralImage?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['DateTime']>;
};

export class FlyerOfferGroupFlyerDto {
  id: Scalars['ID'];
  ofertaFoco: Scalars['Boolean'];
  offerGroupFlyerRegionsDDD?: Maybe<Array<FlyerOfferGroupFlyerRegionsDdddto>>;
  urlOfferMapFooterImage?: Maybe<Scalars['String']>;
  urlOfferMapHeaderImage?: Maybe<Scalars['String']>;
  urlOfferMapImage?: Maybe<Scalars['String']>;
};

export class FlyerOfferGroupFlyerRegionsDdddto {
  regionDdd: FlyerRegionDddDto;
};

export class FlyerOfferGroupRegionDto {
  region: FlyerRegionDto;
};

export class FlyerOfferTypeDto {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export class FlyerRegionDto {
  id: Scalars['ID'];
  regionDdd?: Maybe<Array<FlyerRegionDddDto>>;
  title: Scalars['String'];
};

export class FlyerRegionDddDto {
  ddd: Scalars['Float'];
  id: Scalars['ID'];
};

export class HighLightRoleGroupInput {
  roleGroupId: Scalars['String'];
};

export class Highlight {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  highlightRegion?: Maybe<Array<HighlightRegion>>;
  highlightRegionsDDD?: Maybe<Array<HighlightRegionDdd>>;
  highlightRoleGroup?: Maybe<Array<HighlightRoleGroup>>;
  highlightStories?: Maybe<Array<HighlightStory>>;
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
};

export class HighlightDddInput {
  dddId: Scalars['String'];
};

export class HighlightPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Highlight>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class HighlightRegion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight: Highlight;
  id: Scalars['ID'];
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
};

export class HighlightRegionDdd {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight: Highlight;
  id: Scalars['ID'];
  regionDdd?: Maybe<RegionDdd>;
  updatedAt: Scalars['DateTime'];
};

export class HighlightRegionInput {
  regionId: Scalars['String'];
};

export class HighlightRoleGroup {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight: Highlight;
  id?: Maybe<Scalars['ID']>;
  roleGroupId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class HighlightStory {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight: Highlight;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
};

export class InfoUserTim {
  count?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Material>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export class JobTitle {
  name?: Maybe<Scalars['String']>;
};

export class ListColorPaletteDto {
  hexColor1: Scalars['String'];
  hexColor2: Scalars['String'];
  hexColor3: Scalars['String'];
};

export class ListOfferGroupDto {
  colorPalette?: Maybe<ListColorPaletteDto>;
  id: Scalars['ID'];
  offerGroupRegion?: Maybe<Array<ListOfferGroupRegionDto>>;
  title: Scalars['String'];
  urlLogo?: Maybe<Scalars['String']>;
};

export class ListOfferGroupRegionDto {
  id: Scalars['ID'];
  region: ListRegionDto;
};

export class ListOfferTypeDto {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroups?: Maybe<Array<ListOfferGroupDto>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class ListRegionDto {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export class LoginToken {
  token?: Maybe<Scalars['String']>;
};

export class Material {
  _id?: Maybe<Scalars['String']>;
  author?: Maybe<MaterialAuthor>;
  communicationItemsConfig?: Maybe<MaterialCommunicationConfig>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Array<FileMaterial>>;
  kind?: Maybe<Scalars['String']>;
  numericId?: Maybe<Scalars['Float']>;
  pinned?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  validity?: Maybe<MaterialValidity>;
};

export class MaterialAuthor {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class MaterialCommunicationConfig {
  kind?: Maybe<Scalars['String']>;
};

export class MaterialFindOne {
  _id?: Maybe<Scalars['String']>;
  author?: Maybe<MaterialAuthor>;
  communicationItemsConfig?: Maybe<MaterialCommunicationConfig>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<EmailMaterial>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Array<FileMaterial>>;
  html?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  numericId?: Maybe<Scalars['Float']>;
  pinned?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  validity?: Maybe<MaterialValidity>;
};

export class MaterialValidity {
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export class MigrationGuide {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroups?: Maybe<Array<OfferGroup>>;
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlFile?: Maybe<Scalars['String']>;
};

export class MigrationGuidePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<MigrationGuide>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Mutation {
  /** Generate Url Award */
  GenerateUrlAward: Scalars['String'];
  /** Login With Password */
  LoginWithPassword: UserTim;
  /** Login With Token */
  LoginWithToken: UserTim;
  /** Redirect to Admin with tim Token */
  RedirectAdminToken: UserTim;
  /** Remove Favorite Informativo */
  RemoveFavoriteInformativo: Scalars['Boolean'];
  /** Set Informativo As Favorite */
  SetInformativoAsFavorite: Scalars['Boolean'];
  /** Set Notification As Read */
  SetNotificationAsRead: Scalars['Boolean'];
  changePassword: DefaultMessage;
  /** Create new Attachment */
  createAttachment: Attachment;
  createBanner: Banner;
  /** Create new Conversion */
  createConversion: Conversion;
  /** Create new ConversionGuide */
  createConversionGuide: ConversionGuide;
  /** Create new ConversionGuide Opportunity */
  createConversionGuideOpportunity: ConversionGuideOpportunities;
  /** Create new Coverage */
  createCoverage: Coverage;
  /** Create new Enum Type */
  createEnumType: EnumType;
  /** Create new Favorite Informative */
  createFavoriteInformative: DefaultMessage;
  /** Create new Favorite Offer */
  createFavoriteOffer: DefaultMessage;
  /** Create new Highlight */
  createHighlight: Highlight;
  /** Create new Highlight Story */
  createHighlightStory: HighlightStory;
  /** Create new Migration Guide */
  createMigrationGuide: MigrationGuide;
  /** Create new Offer Group */
  createOfferGroup: OfferGroup;
  /** Create new Offer Group Commandments */
  createOfferGroupCommandments: OfferGroupCommandments;
  /** Create new Offer Group Flyer */
  createOfferGroupFlyer: CreateOfferGroupFlyerResponse;
  /** Create new Offer Map */
  createOfferMap: OfferMap;
  /** Create new PartnerApp */
  createPartnerApp: PartnerApp;
  /** Create new User */
  createUser: User;
  /** Remove Attachment By Id */
  deleteAttachment: DefaultMessage;
  /** Remove Conversion By Id */
  deleteConversion: DefaultMessage;
  /** Remove Conversion Guides By Id */
  deleteConversionGuide: DefaultMessage;
  /** Remove Conversion Guides Opportunity By Id */
  deleteConversionGuideOpportunity: DefaultMessage;
  /** Remove Coverage By Id */
  deleteCoverage: DefaultMessage;
  /** Remove Highlight By Id */
  deleteHighlight: DefaultMessage;
  /** Remove Highlight Story By Id */
  deleteHighlightStory: DefaultMessage;
  /** Remove Image In Offer Group By Id */
  deleteImageOfferGroup: DefaultMessage;
  /** Remove Image In Offer Group Flyer By Id */
  deleteImageOfferGroupFlyer: DefaultMessage;
  /** Remove Migration Guide By Id */
  deleteMigrationGuide: DefaultMessage;
  /** Remove Offer Group By Id */
  deleteOfferGroup: DefaultMessage;
  /** Remove Offer Group Flyer By Id */
  deleteOfferGroupFlyer: DefaultMessage;
  /** Remove PartnerApp By Id */
  deletePartnerApp: DefaultMessage;
  /** Remove User By Id */
  deleteUser: DefaultMessage;
  /** Duplicate Offer Group Flyer Complete */
  duplicateOfferGroupComplete: DefaultMessage;
  /** Duplicate Offer Group Flyer Complete */
  duplicateOfferGroupFlyer: DuplicateOfferGroupFlyerResponse;
  forgotPassword: DefaultMessage;
  login: AuthType;
  removeBanner: DefaultMessage;
  removeEnumType: DefaultMessage;
  resetPassword: DefaultMessage;
  /** Update Attachment */
  updateAttachment: Attachment;
  updateBanner: Banner;
  /** Update Conversion */
  updateConversion: Conversion;
  /** Update Conversion Guides */
  updateConversionGuide: ConversionGuide;
  /** Update Conversion Guides Opportunity */
  updateConversionGuideOpportunity: ConversionGuideOpportunities;
  /** Update Coverage */
  updateCoverage: Coverage;
  updateEnumType: EnumType;
  /** Update Highlight */
  updateHighlight: Highlight;
  /** Update Migration Guide */
  updateMigrationGuide: MigrationGuide;
  /** Create/update new Offer Group Extra */
  updateOfferGroup: OfferGroup;
  /** update new Offer Group Commandments */
  updateOfferGroupCommandments: OfferGroupCommandments;
  /** Update Offer Group Flyer By d */
  updateOfferGroupFlyer: UpdateOfferGroupFlyerResponse;
  /** Update PartnerApp */
  updatePartnerApp: PartnerApp;
  /** Update User */
  updateUser: User;
};


export class MutationLoginWithPasswordArgs {
  data: UserLoginInput;
};


export class MutationRedirectAdminTokenArgs {
  token: Scalars['String'];
};


export class MutationRemoveFavoriteInformativoArgs {
  informativoId: Scalars['String'];
};


export class MutationSetInformativoAsFavoriteArgs {
  informativoId: Scalars['String'];
};


export class MutationSetNotificationAsReadArgs {
  filter: SetNotificationFilter;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateAttachmentArgs {
  data: CreateAttachmentInput;
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateConversionArgs {
  data: CreateConversionInput;
};


export class MutationCreateConversionGuideArgs {
  data: CreateConversionGuideInput;
};


export class MutationCreateConversionGuideOpportunityArgs {
  data: CreateConversionGuideOpportunityInput;
};


export class MutationCreateCoverageArgs {
  data: CreateCoverageInput;
};


export class MutationCreateEnumTypeArgs {
  data: CreateEnumTypeInput;
};


export class MutationCreateFavoriteInformativeArgs {
  data: CreateFavoriteInformativeInput;
};


export class MutationCreateFavoriteOfferArgs {
  data: CreateFavoriteInput;
};


export class MutationCreateHighlightArgs {
  data: CreateHighlightInput;
};


export class MutationCreateHighlightStoryArgs {
  data: CreateHighlightStoryInput;
};


export class MutationCreateMigrationGuideArgs {
  data: CreateMigrationGuideInput;
};


export class MutationCreateOfferGroupArgs {
  data: CreateOfferGroupInput;
};


export class MutationCreateOfferGroupCommandmentsArgs {
  data: CreateOfferGroupCommandmentsInput;
};


export class MutationCreateOfferGroupFlyerArgs {
  data: CreateOfferGroupFlyerInput;
};


export class MutationCreateOfferMapArgs {
  data: CreateOfferMapInput;
};


export class MutationCreatePartnerAppArgs {
  data: CreatePartnerAppInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteAttachmentArgs {
  id: Scalars['String'];
};


export class MutationDeleteConversionArgs {
  id: Scalars['String'];
};


export class MutationDeleteConversionGuideArgs {
  id: Scalars['String'];
};


export class MutationDeleteConversionGuideOpportunityArgs {
  id: Scalars['String'];
};


export class MutationDeleteCoverageArgs {
  id: Scalars['String'];
};


export class MutationDeleteHighlightArgs {
  id: Scalars['String'];
};


export class MutationDeleteHighlightStoryArgs {
  id: Scalars['String'];
};


export class MutationDeleteImageOfferGroupArgs {
  groupId: Scalars['String'];
  upType: Scalars['String'];
};


export class MutationDeleteImageOfferGroupFlyerArgs {
  flyerId: Scalars['String'];
  upType: Scalars['String'];
};


export class MutationDeleteMigrationGuideArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferGroupArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferGroupFlyerArgs {
  id: Scalars['String'];
};


export class MutationDeletePartnerAppArgs {
  id: Scalars['String'];
};


export class MutationDeleteUserArgs {
  id: Scalars['String'];
};


export class MutationDuplicateOfferGroupCompleteArgs {
  id: Scalars['String'];
};


export class MutationDuplicateOfferGroupFlyerArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationRemoveEnumTypeArgs {
  id: Scalars['String'];
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationUpdateAttachmentArgs {
  data: UpdateAttachmentInput;
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateConversionArgs {
  data: UpdateConversionInput;
};


export class MutationUpdateConversionGuideArgs {
  data: UpdateConversionGuideInput;
};


export class MutationUpdateConversionGuideOpportunityArgs {
  data: UpdateConversionGuideOpportunityInput;
};


export class MutationUpdateCoverageArgs {
  data: UpdateCoverageInput;
};


export class MutationUpdateEnumTypeArgs {
  data: UpdateEnumTypeInput;
};


export class MutationUpdateHighlightArgs {
  data: UpdateHighlightInput;
};


export class MutationUpdateMigrationGuideArgs {
  data: UpdateMigrationGuideInput;
};


export class MutationUpdateOfferGroupArgs {
  data: CreateOfferGroupExtraInput;
};


export class MutationUpdateOfferGroupCommandmentsArgs {
  data: UpdateOfferGroupCommandmentsInput;
};


export class MutationUpdateOfferGroupFlyerArgs {
  data: UpdateOfferGroupFlyerInput;
};


export class MutationUpdatePartnerAppArgs {
  data: UpdatePartnerAppInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};

export class NotificationItems {
  _id?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Scalars['String']>>;
  iconUrl?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  wasRead?: Maybe<Scalars['Boolean']>;
};

export class NotificationPagination {
  count?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<NotificationItems>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export class Notifications {
  readIds?: Maybe<Array<Scalars['String']>>;
  unreadIds?: Maybe<Array<Scalars['String']>>;
};

export class OfferGroup {
  colorPalette: ColorPalette;
  conversion?: Maybe<Conversion>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  descriptive?: Maybe<Scalars['String']>;
  favoriteOffer?: Maybe<Array<FavoriteOffer>>;
  finished: Scalars['Boolean'];
  groupId?: Maybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  id: Scalars['ID'];
  migrationGuide?: Maybe<MigrationGuide>;
  offerGroupChannel?: Maybe<Array<OfferGroupChannel>>;
  offerGroupCommandments?: Maybe<Array<OfferGroupCommandments>>;
  offerGroupFlyer?: Maybe<Array<OfferGroupFlyer>>;
  offerGroupRegion?: Maybe<Array<OfferGroupRegion>>;
  offerMap?: Maybe<Array<OfferMap>>;
  offerSegment: OfferSegment;
  offerType: OfferType;
  portabilityRules?: Maybe<Scalars['String']>;
  serviceChannels?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  urlBackgroundImage?: Maybe<Scalars['String']>;
  urlBannerImage?: Maybe<Scalars['String']>;
  urlBonusImage?: Maybe<Scalars['String']>;
  urlDescriptiveGeneralImage?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  urlOfferMapCommandmentsImage?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['DateTime']>;
};

export class OfferGroupChannel {
  channelId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  offerGroup: OfferGroup;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class OfferGroupChannelDto {
  channelId: Scalars['String'];
  id: Scalars['ID'];
};

export class OfferGroupChannelInput {
  channelId?: InputMaybe<Scalars['String']>;
};

export class OfferGroupCommandments {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  number: Scalars['Float'];
  offerGroup: OfferGroup;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
};

export class OfferGroupDto {
  colorPalette?: Maybe<ColorPaletteDto>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  descriptive?: Maybe<Scalars['String']>;
  finished: Scalars['Boolean'];
  groupId?: Maybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  id: Scalars['ID'];
  offerGroupChannel?: Maybe<Array<OfferGroupChannelDto>>;
  offerGroupFlyer?: Maybe<Array<OfferGroupFlyerDto>>;
  offerGroupRegion?: Maybe<Array<OfferGroupRegionDto>>;
  offerType?: Maybe<OfferTypeDto>;
  portabilityRules?: Maybe<Scalars['String']>;
  serviceChannels?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  urlBackgroundImage?: Maybe<Scalars['String']>;
  urlBannerImage?: Maybe<Scalars['String']>;
  urlBonusImage?: Maybe<Scalars['String']>;
  urlDescriptiveGeneralImage?: Maybe<Scalars['String']>;
  urlLogo?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['DateTime']>;
};

export class OfferGroupFlyer {
  b1Ate: Scalars['Boolean'];
  b1BonusDoPlano?: Maybe<Scalars['String']>;
  b1Download?: Maybe<Scalars['String']>;
  b1Plano?: Maybe<Scalars['String']>;
  b1Quantidade?: Maybe<Scalars['String']>;
  b1Subtitulo?: Maybe<Scalars['String']>;
  b1Titulo?: Maybe<Scalars['String']>;
  b1Upload?: Maybe<Scalars['String']>;
  b2InternetAcumulada?: Maybe<Scalars['String']>;
  b2RegrasRedeSocial?: Maybe<Scalars['String']>;
  b2Titulo?: Maybe<Scalars['String']>;
  b2_1Rodape?: Maybe<Scalars['String']>;
  b2_1Separador?: Maybe<Scalars['String']>;
  b2_5InternetNoAviao: Scalars['Boolean'];
  b2_5Roaming?: Maybe<Scalars['String']>;
  b2_5RoamingBonus?: Maybe<Scalars['String']>;
  b2_5RoamingBonusDescritivo?: Maybe<Scalars['String']>;
  b2_5RoamingPlus: Scalars['Boolean'];
  b2_5RoamingQtd1?: Maybe<Scalars['String']>;
  b2_5RoamingQtd2?: Maybe<Scalars['String']>;
  b2_5RoamingText1?: Maybe<Scalars['String']>;
  b2_5RoamingText2?: Maybe<Scalars['String']>;
  b2_6Descritivo?: Maybe<Scalars['String']>;
  b2_6Modalidades?: Maybe<Scalars['String']>;
  b2_7UrlExclusivoTitularImage?: Maybe<Scalars['String']>;
  b3InternetAcumulada?: Maybe<Scalars['String']>;
  b3RegrasRedeSocial?: Maybe<Scalars['String']>;
  b3Titulo?: Maybe<Scalars['String']>;
  b3_3UrlExclusivoDependenteImage?: Maybe<Scalars['String']>;
  b4_1FidelizadoDe: Scalars['Boolean'];
  b4_1FidelizadoDescritivo?: Maybe<Scalars['String']>;
  b4_1FidelizadoValorDe?: Maybe<Scalars['Float']>;
  b4_1FidelizadoValorPor?: Maybe<Scalars['Float']>;
  b4_1NaoFidelizadoDe: Scalars['Boolean'];
  b4_1NaoFidelizadoDescritivo?: Maybe<Scalars['String']>;
  b4_1NaoFidelizadoValorDe?: Maybe<Scalars['Float']>;
  b4_1NaoFidelizadoValorPor?: Maybe<Scalars['Float']>;
  b4_1NomeSistemico?: Maybe<Scalars['String']>;
  b4_1Titulo?: Maybe<Scalars['String']>;
  b4_2FidelizadoDe: Scalars['Boolean'];
  b4_2FidelizadoDescritivo?: Maybe<Scalars['String']>;
  b4_2FidelizadoValorDe?: Maybe<Scalars['Float']>;
  b4_2FidelizadoValorPor?: Maybe<Scalars['Float']>;
  b4_2NaoFidelizadoDe: Scalars['Boolean'];
  b4_2NaoFidelizadoDescritivo?: Maybe<Scalars['String']>;
  b4_2NaoFidelizadoValorDe?: Maybe<Scalars['Float']>;
  b4_2NaoFidelizadoValorPor?: Maybe<Scalars['Float']>;
  b4_2NomeSistemico?: Maybe<Scalars['String']>;
  b4_2Titulo?: Maybe<Scalars['String']>;
  b4_3CreditoLivreValorCredito?: Maybe<Scalars['Float']>;
  b4_3NaoFidelizadoDescritivo?: Maybe<Scalars['String']>;
  b4_3NomeSistemico?: Maybe<Scalars['String']>;
  b4_3PrecoValorDia?: Maybe<Scalars['Float']>;
  b4_3ValorRecarga?: Maybe<Scalars['Float']>;
  b5AparelhoDe: Scalars['Boolean'];
  b5AparelhoDescritivo1?: Maybe<Scalars['String']>;
  b5AparelhoDescritivo2?: Maybe<Scalars['String']>;
  b5AparelhoPlano?: Maybe<Scalars['String']>;
  b5AparelhoValorAvista?: Maybe<Scalars['Float']>;
  b5AparelhoValorDe?: Maybe<Scalars['Float']>;
  b5AparelhoValorPor?: Maybe<Scalars['Float']>;
  b5ColorPalette?: Maybe<ColorPalette>;
  b5DescritivoArmazenamento?: Maybe<Scalars['String']>;
  b5Icon5G: Scalars['Boolean'];
  b5IconSIM: Scalars['Boolean'];
  b5Parcelamento?: Maybe<Scalars['String']>;
  b5Separador?: Maybe<Scalars['String']>;
  b5Titulo?: Maybe<Scalars['String']>;
  b5UrlAparelhoImage?: Maybe<Scalars['String']>;
  b6AparelhoDe: Scalars['Boolean'];
  b6AparelhoDescritivo1?: Maybe<Scalars['String']>;
  b6AparelhoDescritivo2?: Maybe<Scalars['String']>;
  b6AparelhoPlano?: Maybe<Scalars['String']>;
  b6AparelhoValorAvista?: Maybe<Scalars['Float']>;
  b6AparelhoValorDe?: Maybe<Scalars['Float']>;
  b6AparelhoValorPor?: Maybe<Scalars['Float']>;
  b6ColorPalette?: Maybe<ColorPalette>;
  b6DescritivoArmazenamento?: Maybe<Scalars['String']>;
  b6Icon5G: Scalars['Boolean'];
  b6IconSIM: Scalars['Boolean'];
  b6Parcelamento?: Maybe<Scalars['String']>;
  b6Separador?: Maybe<Scalars['String']>;
  b6Titulo?: Maybe<Scalars['String']>;
  b6UrlAparelhoImage?: Maybe<Scalars['String']>;
  b7AparelhoDe: Scalars['Boolean'];
  b7AparelhoDescritivo1?: Maybe<Scalars['String']>;
  b7AparelhoDescritivo2?: Maybe<Scalars['String']>;
  b7AparelhoPlano?: Maybe<Scalars['String']>;
  b7AparelhoValorAvista?: Maybe<Scalars['Float']>;
  b7AparelhoValorDe?: Maybe<Scalars['Float']>;
  b7AparelhoValorPor?: Maybe<Scalars['Float']>;
  b7ColorPalette?: Maybe<ColorPalette>;
  b7DescritivoArmazenamento?: Maybe<Scalars['String']>;
  b7Icon5G: Scalars['Boolean'];
  b7IconSIM: Scalars['Boolean'];
  b7Parcelamento?: Maybe<Scalars['String']>;
  b7Titulo?: Maybe<Scalars['String']>;
  b7UrlAparelhoImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataFinal?: Maybe<Scalars['DateTime']>;
  dataInicial?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  novaOferta: Scalars['Boolean'];
  ofertaFoco: Scalars['Boolean'];
  offerGroup?: Maybe<OfferGroup>;
  offerGroupFlyerAdvantage?: Maybe<Array<OfferGroupFlyerAdvantage>>;
  offerGroupFlyerPremiumBenefit?: Maybe<Array<OfferGroupFlyerPremiumBenefit>>;
  offerGroupFlyerRegionsDDD?: Maybe<Array<OfferGroupFlyerRegionDdd>>;
  offerGroupFlyerSocialNetwork?: Maybe<Array<OfferGroupFlyerSocialNetworks>>;
  offerGroupFlyerSubscriptions?: Maybe<Array<OfferGroupFlyerSubscriptions>>;
  offerMapFlyer?: Maybe<Array<OfferMapFlyer>>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlOfferMapFooterImage?: Maybe<Scalars['String']>;
  urlOfferMapHeaderImage?: Maybe<Scalars['String']>;
  urlOfferMapImage?: Maybe<Scalars['String']>;
};

export class OfferGroupFlyerAdvantage {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer: OfferGroupFlyer;
  partnerApp?: Maybe<PartnerApp>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupFlyerAdvantagesInput {
  advantageId: Scalars['String'];
  /** Enum("holder","dependent") */
  type: Scalars['String'];
};

export class OfferGroupFlyerDddInput {
  dddId: Scalars['String'];
};

export class OfferGroupFlyerDto {
  id: Scalars['ID'];
  ofertaFoco?: Maybe<Scalars['Boolean']>;
  urlOfferMapFooterImage?: Maybe<Scalars['String']>;
  urlOfferMapHeaderImage?: Maybe<Scalars['String']>;
  urlOfferMapImage?: Maybe<Scalars['String']>;
};

export class OfferGroupFlyerPremiumBenefit {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer: OfferGroupFlyer;
  partnerApp?: Maybe<PartnerApp>;
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupFlyerPremiumBenefitInput {
  premiumBenefitId: Scalars['String'];
};

export class OfferGroupFlyerRegionDdd {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer: OfferGroupFlyer;
  regionDdd?: Maybe<RegionDdd>;
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupFlyerSocialNetworks {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer: OfferGroupFlyer;
  socialNetwork?: Maybe<SocialNetwork>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupFlyerSocialNetworksInput {
  socialNetworkId: Scalars['String'];
  /** Enum("holder","dependent") */
  type: Scalars['String'];
};

export class OfferGroupFlyerSubscriptions {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer: OfferGroupFlyer;
  partnerApp?: Maybe<PartnerApp>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupFlyerSubscriptionsInput {
  subscriptionId: Scalars['String'];
  /** Enum("holder","dependent") */
  type: Scalars['String'];
};

export class OfferGroupPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<OfferGroupDto>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class OfferGroupRegion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroup: OfferGroup;
  region?: Maybe<Region>;
  updatedAt: Scalars['DateTime'];
};

export class OfferGroupRegionDto {
  id: Scalars['ID'];
  region: RegionDto;
};

export class OfferGroupRegionInput {
  regionId?: InputMaybe<Scalars['String']>;
};

export class OfferMap {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fixCommandmentsOfferGroup: Scalars['String'];
  fixConversionOfferGroup: Scalars['String'];
  fixDetailOfferGroup: Scalars['String'];
  fixMigrationOfferGroup: Scalars['String'];
  id: Scalars['ID'];
  offerGroup?: Maybe<OfferGroup>;
  offerMapFlyer?: Maybe<Array<OfferMapFlyer>>;
  updatedAt: Scalars['DateTime'];
  userTimId: Scalars['String'];
};

export class OfferMapFlyer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroupFlyer?: Maybe<OfferGroupFlyer>;
  offerMap: OfferMap;
  updatedAt: Scalars['DateTime'];
};

export class OfferSegment {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroups?: Maybe<Array<OfferGroup>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class OfferType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offerGroups?: Maybe<Array<OfferGroup>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class OfferTypeDto {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class PaginationInfo {
  currentPage?: Maybe<Scalars['Float']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  itemCount?: Maybe<Scalars['Float']>;
  pageCount?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
};

export class PartnerApp {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  howToActivate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includedCombo?: Maybe<Scalars['String']>;
  offerGroupFlyerAdvantage?: Maybe<Array<OfferGroupFlyerSubscriptions>>;
  offerGroupFlyerPremiumBenefit?: Maybe<Array<OfferGroupFlyerPremiumBenefit>>;
  offerGroupFlyerSubscriptions?: Maybe<Array<OfferGroupFlyerSubscriptions>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export class PartnerAppPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PartnerApp>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Query {
  /** Channel Find One */
  ChannelFindOne: ChannelsModel;
  /** Current User Tim */
  CurrentUserTim: UserLogin;
  /** Get Channels */
  GetChannels: Array<ChannelsModel>;
  /** Get Informativos By Id */
  GetInformativoById: MaterialFindOne;
  /** Get Informativos */
  GetInformativos: InfoUserTim;
  /** Get Regions Find Many */
  GetRegionsFindMany: RegionFindMany;
  /** Informativos Favoritos */
  InformativosFavorite: Array<Material>;
  /** Token User Tim */
  TokenTim: Scalars['String'];
  /** User Tim Balance */
  UserBalance: UserBalance;
  /** Get User Extract */
  UserExtract: UserExtract;
  /** Get User Notification */
  UserNotification: NotificationPagination;
  /** Return Attachment By Id for admin */
  adminAttachment: Attachment;
  /** Return All Attachments for Admin */
  adminAttachments: AttachmentPage;
  /** Return All Banners For Admin */
  adminBanners: BannerPage;
  /** Return Conversion By ID For Admin */
  adminConversion: Conversion;
  /** Return All Conversion Guides By ID For Admin */
  adminConversionGuide: ConversionGuide;
  /** Return All Conversion Guides Opportunity By ID For Admin */
  adminConversionGuideOpportunity: ConversionGuideOpportunities;
  /** Return All conversion Guides For Admin */
  adminConversionGuides: ConversionGuidePage;
  /** Return All conversion For Admin */
  adminConversions: ConversionPage;
  /** Return Coverage By Id */
  adminCoverage: Coverage;
  /** Return All Coverages For Admin */
  adminCoverages: CoveragePage;
  /** Return All Highlight for Admin */
  adminHighlight: HighlightPage;
  /** Return All Migration Guide for Admin */
  adminMigrationGuides: MigrationGuidePage;
  /** Return Advantage Offer Group Flyer By ID */
  adminOfferGroupFlyerAdvantageById: Array<OfferGroupFlyerAdvantage>;
  /** Return Offer Group Flyer By ID */
  adminOfferGroupFlyerById: OfferGroupFlyer;
  /** Return Premium Benefit Offer Group Flyer By ID */
  adminOfferGroupFlyerPremiumBenefitById: Array<OfferGroupFlyerPremiumBenefit>;
  /** Return Social Networks Offer Group Flyer By ID */
  adminOfferGroupFlyerSocialNetworksById: Array<OfferGroupFlyerSocialNetworks>;
  /** Return Subscriptions Offer Group Flyer By ID */
  adminOfferGroupFlyerSubscriptionsById: Array<OfferGroupFlyerSubscriptions>;
  /** Return All Offers Group in Admin */
  adminOffersGroup: OfferGroupPage;
  /** Return partnerApp By Id for admin */
  adminPartnerApp: PartnerApp;
  /** Return All PartnerApp for Admin */
  adminPartnerApps: PartnerAppPage;
  /** Return Attachment By ID */
  attachment: Attachment;
  /** Return All Attachments */
  attachments: Array<Attachment>;
  banner: Banner;
  /** Return All Banners */
  banners: Array<Banner>;
  /** Return Color Palette By ID */
  colorPalette: ColorPalette;
  /** Return All Colors Palette */
  colorsPalette: Array<ColorPalette>;
  /** Return All conversion For App */
  conversions: Array<Conversion>;
  /** Return Coverage By Address */
  coverageByAddress: Coverage;
  /** Return User Logged */
  currentUser: User;
  /** Return DDD By Regions IDs */
  dddByRegion: Array<RegionDdd>;
  /** Return All DDDs */
  ddds: Array<RegionDdd>;
  enumByType: Array<EnumType>;
  enumType: EnumType;
  enumTypes: Array<EnumType>;
  favoriteInformativeById: FavoriteInformative;
  favoriteOfferById: FavoriteOffer;
  favoritesInformativeByUserTimId: Array<FavoriteInformative>;
  favoritesOfferByUserTimId: Array<FavoriteOffer>;
  /** Return ddd By ID */
  findDDDById: RegionDdd;
  /** Return Flyer By ID for duplicate */
  findForDuplicateFlyer: OfferGroupFlyer;
  /** Return Offer Group By ID for update Admin */
  findUpdateOfferGroupById: OfferGroup;
  /** Return Highlight By ID */
  highlight: Highlight;
  /** Return Highlight Story By ID */
  highlightStory: HighlightStory;
  /** Return All Highlights */
  highlights: Array<Highlight>;
  /** Return Offer Group By ID */
  internOfferGroupById: OfferGroup;
  /** Return All Conversion List in admin */
  listConversion: Array<Conversion>;
  /** Return All Migration Guide List in admin */
  listMigrationGuides: Array<MigrationGuide>;
  /** Return All Offers Segments List in admin */
  listOffersSegments: Array<OfferSegment>;
  /** Return All Offers Types List in admin */
  listOffersTypes: Array<OfferType>;
  /** Return Migration Guide By Id for admin */
  migrationGuideById: MigrationGuide;
  /** Return Offer Group By ID */
  offerGroupById: FlyerOfferGroupDto;
  /** Return Offer Group By ID & Channel */
  offerGroupByIdChannel: OfferGroup;
  /** Return Offer Group By ID & DDD */
  offerGroupByIdDDD: OfferGroup;
  /** Return Offer Group By ID & Region */
  offerGroupByIdRegion: OfferGroup;
  /** Return Offer Group Commandments By Group ID */
  offerGroupCommandmentsByGroupId: Array<OfferGroupCommandments>;
  /** Return Offer Group Commandments By ID */
  offerGroupCommandmentsById: OfferGroupCommandments;
  /** Return Offer Group Conversion By Group ID */
  offerGroupConversionByGroupId: Conversion;
  /** Return Offer Group Migration By Group ID */
  offerGroupMigrationByGroupId: MigrationGuide;
  offerMapById: OfferMap;
  offerMapByUserTimId: Array<OfferMap>;
  /** Return All Offers Group */
  offersGroup: Array<ListOfferTypeDto>;
  /** Return All Offers Group Highlight */
  offersGroupHighlight: Array<OfferGroup>;
  /** Return PartnerApp By ID */
  partnerApp: PartnerApp;
  /** Return All PartnerApp */
  partnerApps: Array<PartnerApp>;
  /** Return Region By ID */
  region: Region;
  /** Return All Regions */
  regions: Array<Region>;
  /** Return Social Networks By ID */
  socialNetwork: SocialNetwork;
  /** Return All Social Networks */
  socialNetworks: Array<SocialNetwork>;
  /** Return State By ID */
  state: State;
  /** Return All States */
  states: Array<State>;
  /** Return User By Id */
  user: User;
  /** Return All users paged */
  users: UserPage;
};


export class QueryChannelFindOneArgs {
  name: Scalars['String'];
};


export class QueryGetInformativoByIdArgs {
  informativoId: Scalars['String'];
};


export class QueryGetInformativosArgs {
  filter: FilterFindManyMaterialInput;
};


export class QueryGetRegionsFindManyArgs {
  filter: RegionFindManyFilter;
};


export class QueryUserExtractArgs {
  filter: UserExtractFilter;
};


export class QueryUserNotificationArgs {
  filter: UserNotificationFilter;
};


export class QueryAdminAttachmentArgs {
  id: Scalars['String'];
};


export class QueryAdminAttachmentsArgs {
  data: SearchAttachmentInput;
};


export class QueryAdminBannersArgs {
  data: SearchBannerInput;
};


export class QueryAdminConversionArgs {
  id: Scalars['String'];
};


export class QueryAdminConversionGuideArgs {
  id: Scalars['String'];
};


export class QueryAdminConversionGuideOpportunityArgs {
  id: Scalars['String'];
};


export class QueryAdminConversionGuidesArgs {
  data: SearchConversionGuideInput;
};


export class QueryAdminConversionsArgs {
  data: SearchConversionInput;
};


export class QueryAdminCoverageArgs {
  id: Scalars['String'];
};


export class QueryAdminCoveragesArgs {
  data: SearchCoverageInput;
};


export class QueryAdminHighlightArgs {
  data: SearchHighlightInput;
};


export class QueryAdminMigrationGuidesArgs {
  data: SearchMigrationGuideInput;
};


export class QueryAdminOfferGroupFlyerAdvantageByIdArgs {
  flyerId: Scalars['String'];
};


export class QueryAdminOfferGroupFlyerByIdArgs {
  id: Scalars['String'];
};


export class QueryAdminOfferGroupFlyerPremiumBenefitByIdArgs {
  flyerId: Scalars['String'];
};


export class QueryAdminOfferGroupFlyerSocialNetworksByIdArgs {
  flyerId: Scalars['String'];
};


export class QueryAdminOfferGroupFlyerSubscriptionsByIdArgs {
  flyerId: Scalars['String'];
};


export class QueryAdminOffersGroupArgs {
  data: SearchOfferGroupInput;
};


export class QueryAdminPartnerAppArgs {
  id: Scalars['String'];
};


export class QueryAdminPartnerAppsArgs {
  data: SearchPartnerAppInput;
};


export class QueryAttachmentArgs {
  id: Scalars['String'];
};


export class QueryBannerArgs {
  id: Scalars['String'];
};


export class QueryColorPaletteArgs {
  id: Scalars['String'];
};


export class QueryCoverageByAddressArgs {
  address: Scalars['String'];
};


export class QueryDddByRegionArgs {
  id: Array<Scalars['String']>;
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeArgs {
  id: Scalars['String'];
};


export class QueryFavoriteInformativeByIdArgs {
  id: Scalars['String'];
};


export class QueryFavoriteOfferByIdArgs {
  id: Scalars['String'];
};


export class QueryFindDddByIdArgs {
  id: Scalars['String'];
};


export class QueryFindForDuplicateFlyerArgs {
  id: Scalars['String'];
};


export class QueryFindUpdateOfferGroupByIdArgs {
  id: Scalars['String'];
};


export class QueryHighlightArgs {
  id: Scalars['String'];
};


export class QueryHighlightStoryArgs {
  id: Scalars['String'];
};


export class QueryInternOfferGroupByIdArgs {
  id: Scalars['String'];
};


export class QueryMigrationGuideByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferGroupByIdArgs {
  dddId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export class QueryOfferGroupByIdChannelArgs {
  channelId: Scalars['String'];
  id: Scalars['String'];
};


export class QueryOfferGroupByIdDddArgs {
  dddId: Scalars['String'];
  id: Scalars['String'];
};


export class QueryOfferGroupByIdRegionArgs {
  id: Scalars['String'];
  region: Scalars['String'];
};


export class QueryOfferGroupCommandmentsByGroupIdArgs {
  groupId: Scalars['String'];
};


export class QueryOfferGroupCommandmentsByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferGroupConversionByGroupIdArgs {
  groupId: Scalars['String'];
};


export class QueryOfferGroupMigrationByGroupIdArgs {
  groupId: Scalars['String'];
};


export class QueryOfferMapByIdArgs {
  id: Scalars['String'];
};


export class QueryPartnerAppArgs {
  id: Scalars['String'];
};


export class QueryRegionArgs {
  id: Scalars['String'];
};


export class QuerySocialNetworkArgs {
  id: Scalars['String'];
};


export class QueryStateArgs {
  id: Scalars['String'];
};


export class QueryUserArgs {
  id: Scalars['String'];
};


export class QueryUsersArgs {
  data: SearchUserInput;
};

export class Region {
  attachmentRegion?: Maybe<Array<AttachmentRegion>>;
  bannerRegions?: Maybe<Array<BannerRegion>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlightRegions?: Maybe<Array<HighlightRegion>>;
  id: Scalars['ID'];
  offerGroupFlyerRegion?: Maybe<Array<OfferGroupRegion>>;
  regionDdd?: Maybe<Array<RegionDdd>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class RegionDto {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export class RegionDdd {
  attachmentRegionDdd?: Maybe<Array<AttachmentRegionDdd>>;
  bannerRegionDdd?: Maybe<Array<BannerRegionDdd>>;
  createdAt: Scalars['DateTime'];
  ddd: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlightRegionDdd?: Maybe<Array<HighlightRegionDdd>>;
  id: Scalars['ID'];
  offerGroupFlyerRegionDdd?: Maybe<Array<OfferGroupFlyerRegionDdd>>;
  region: Region;
  updatedAt: Scalars['DateTime'];
};

export class RegionFindMany {
  itemIds?: Maybe<Array<Scalars['String']>>;
};

export class RegionFindManyFilter {
  name?: InputMaybe<Scalars['String']>;
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class RoleGroup {
  _id?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class RoleGroupIdmItems {
  _id: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export class SearchAttachmentInput {
  dddId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchBannerInput {
  dddId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Array<Scalars['String']>>;
  roleGroup?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchConversionGuideInput {
  conversionId?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchConversionInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchCoverageInput {
  coverageType?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchHighlightInput {
  dddId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Array<Scalars['String']>>;
  roleGroupId?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchMigrationGuideInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchOfferGroupInput {
  channelsId?: InputMaybe<Array<Scalars['String']>>;
  dddId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchPartnerAppInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SetNotificationFilter {
  isRead?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Array<Scalars['String']>>;
};

export class SocialNetwork {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  offerGroupFlyerSocialNetwork?: Maybe<Array<OfferGroupFlyerSocialNetworks>>;
  updatedAt: Scalars['DateTime'];
  urlIcon?: Maybe<Scalars['String']>;
};

export enum SortFindManyMaterialInput {
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyNotificationInput {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export class State {
  capital: Scalars['String'];
  coverages?: Maybe<Array<Coverage>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  region: Scalars['String'];
  state: Scalars['String'];
  uf: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SubChannels {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<ChannelRoleGroups>>;
};

export class UpdateAttachmentInput {
  DDDs?: InputMaybe<Array<AttachmentDddInput>>;
  id: Scalars['String'];
  regions?: InputMaybe<Array<AttachmentRegionInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateBannerInput {
  DDDs?: InputMaybe<Array<BannerDddInput>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<BannerRegionInput>>;
  roleGroups?: InputMaybe<Array<BannerRoleGroupInput>>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateConversionGuideInput {
  customerArrived?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  whatToDo?: InputMaybe<Scalars['String']>;
};

export class UpdateConversionGuideOpportunityInput {
  conversionGuideOpportunityId: Scalars['String'];
  description: Scalars['String'];
};

export class UpdateConversionInput {
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateCoverageInput {
  city?: InputMaybe<Scalars['String']>;
  fiveG: Scalars['Boolean'];
  id: Scalars['String'];
  internet: Scalars['Boolean'];
  stateId?: InputMaybe<Scalars['String']>;
  ultraFibra: Scalars['Boolean'];
};

export class UpdateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export class UpdateHighlightInput {
  DDDs?: InputMaybe<Array<HighlightDddInput>>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  regions?: InputMaybe<Array<HighlightRegionInput>>;
  roleGroups?: InputMaybe<Array<HighLightRoleGroupInput>>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateMigrationGuideInput {
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferGroupFlyerInput {
  DDDs?: InputMaybe<Array<OfferGroupFlyerDddInput>>;
  advantages?: InputMaybe<Array<OfferGroupFlyerAdvantagesInput>>;
  b1Ate?: InputMaybe<Scalars['Boolean']>;
  b1BonusDoPlano?: InputMaybe<Scalars['String']>;
  b1Download?: InputMaybe<Scalars['String']>;
  b1Plano?: InputMaybe<Scalars['String']>;
  b1Quantidade?: InputMaybe<Scalars['String']>;
  b1Subtitulo?: InputMaybe<Scalars['String']>;
  b1Titulo?: InputMaybe<Scalars['String']>;
  b1Upload?: InputMaybe<Scalars['String']>;
  b2InternetAcumulada?: InputMaybe<Scalars['String']>;
  b2RegrasRedeSocial?: InputMaybe<Scalars['String']>;
  b2Titulo?: InputMaybe<Scalars['String']>;
  b2_1Rodape?: InputMaybe<Scalars['String']>;
  b2_1Separador?: InputMaybe<Scalars['String']>;
  b2_5InternetNoAviao?: InputMaybe<Scalars['Boolean']>;
  b2_5Roaming?: InputMaybe<Scalars['String']>;
  b2_5RoamingBonus?: InputMaybe<Scalars['String']>;
  b2_5RoamingBonusDescritivo?: InputMaybe<Scalars['String']>;
  b2_5RoamingPlus?: InputMaybe<Scalars['Boolean']>;
  b2_5RoamingQtd1?: InputMaybe<Scalars['String']>;
  b2_5RoamingQtd2?: InputMaybe<Scalars['String']>;
  b2_5RoamingText1?: InputMaybe<Scalars['String']>;
  b2_5RoamingText2?: InputMaybe<Scalars['String']>;
  b2_6Descritivo?: InputMaybe<Scalars['String']>;
  b2_6Modalidades?: InputMaybe<Scalars['String']>;
  b3InternetAcumulada?: InputMaybe<Scalars['String']>;
  b3RegrasRedeSocial?: InputMaybe<Scalars['String']>;
  b3Titulo?: InputMaybe<Scalars['String']>;
  b4_1FidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_1FidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_1FidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_1FidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_1NaoFidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_1NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_1NaoFidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_1NaoFidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_1NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_1Titulo?: InputMaybe<Scalars['String']>;
  b4_2FidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_2FidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_2FidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_2FidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_2NaoFidelizadoDe?: InputMaybe<Scalars['Boolean']>;
  b4_2NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_2NaoFidelizadoValorDe?: InputMaybe<Scalars['Float']>;
  b4_2NaoFidelizadoValorPor?: InputMaybe<Scalars['Float']>;
  b4_2NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_2Titulo?: InputMaybe<Scalars['String']>;
  b4_3CreditoLivreValorCredito?: InputMaybe<Scalars['Float']>;
  b4_3NaoFidelizadoDescritivo?: InputMaybe<Scalars['String']>;
  b4_3NomeSistemico?: InputMaybe<Scalars['String']>;
  b4_3PrecoValorDia?: InputMaybe<Scalars['Float']>;
  b4_3ValorRecarga?: InputMaybe<Scalars['Float']>;
  b5AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b5AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b5AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b5AparelhoPlano?: InputMaybe<Scalars['String']>;
  b5AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b5AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b5AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b5DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b5Icon5G?: InputMaybe<Scalars['Boolean']>;
  b5IconSIM?: InputMaybe<Scalars['Boolean']>;
  b5Parcelamento?: InputMaybe<Scalars['String']>;
  b5Separador?: InputMaybe<Scalars['String']>;
  b5Titulo?: InputMaybe<Scalars['String']>;
  b5colorPaletteId?: InputMaybe<Scalars['String']>;
  b6AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b6AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b6AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b6AparelhoPlano?: InputMaybe<Scalars['String']>;
  b6AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b6AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b6AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b6DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b6Icon5G?: InputMaybe<Scalars['Boolean']>;
  b6IconSIM?: InputMaybe<Scalars['Boolean']>;
  b6Parcelamento?: InputMaybe<Scalars['String']>;
  b6Separador?: InputMaybe<Scalars['String']>;
  b6Titulo?: InputMaybe<Scalars['String']>;
  b6colorPaletteId?: InputMaybe<Scalars['String']>;
  b7AparelhoDe?: InputMaybe<Scalars['Boolean']>;
  b7AparelhoDescritivo1?: InputMaybe<Scalars['String']>;
  b7AparelhoDescritivo2?: InputMaybe<Scalars['String']>;
  b7AparelhoPlano?: InputMaybe<Scalars['String']>;
  b7AparelhoValorAvista?: InputMaybe<Scalars['Float']>;
  b7AparelhoValorDe?: InputMaybe<Scalars['Float']>;
  b7AparelhoValorPor?: InputMaybe<Scalars['Float']>;
  b7DescritivoArmazenamento?: InputMaybe<Scalars['String']>;
  b7Icon5G?: InputMaybe<Scalars['Boolean']>;
  b7IconSIM?: InputMaybe<Scalars['Boolean']>;
  b7Parcelamento?: InputMaybe<Scalars['String']>;
  b7Titulo?: InputMaybe<Scalars['String']>;
  b7colorPaletteId?: InputMaybe<Scalars['String']>;
  dataFinal?: InputMaybe<Scalars['DateTime']>;
  dataInicial?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  novaOferta?: InputMaybe<Scalars['Boolean']>;
  ofertaFoco?: InputMaybe<Scalars['Boolean']>;
  premiumBenefits?: InputMaybe<Array<OfferGroupFlyerPremiumBenefitInput>>;
  socialNetworks?: InputMaybe<Array<OfferGroupFlyerSocialNetworksInput>>;
  status?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<Array<OfferGroupFlyerSubscriptionsInput>>;
};

export class UpdateOfferGroupFlyerResponse {
  id: Scalars['ID'];
};

export class UpdatePartnerAppInput {
  description?: InputMaybe<Scalars['String']>;
  howToActivate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  includedCombo?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  validity?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  jobRole?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phonePrefix?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Scalars['String']>;
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  jobRole?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pathImage?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export class UserAcceptedTerms {
  term?: Maybe<Scalars['String']>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserBalance {
  balance?: Maybe<Scalars['Float']>;
  expiredPoints?: Maybe<Scalars['Float']>;
  reservedPoints?: Maybe<Scalars['Float']>;
  sessionToken?: Maybe<Scalars['String']>;
  tempToken?: Maybe<Scalars['String']>;
  usedPoints?: Maybe<Scalars['Float']>;
};

export class UserCreditOperation {
  createdAt?: Maybe<Scalars['DateTime']>;
  creditOriginCampaignData?: Maybe<UserCreditOperationCreditOriginCampaign>;
  op?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export class UserCreditOperationCreditOriginCampaign {
  campaignTitle?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export class UserExtract {
  count?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<UserCreditOperation>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export class UserExtractFilter {
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};

export class UserGamefication {
  chips?: Maybe<Scalars['Float']>;
  nivel?: Maybe<Scalars['Float']>;
};

export class UserKpi {
  jobTitle?: Maybe<Scalars['String']>;
};

export class UserLogin {
  user?: Maybe<UserTim>;
};

export class UserLoginInput {
  password: Scalars['String'];
  user: Scalars['String'];
};

export class UserNotificationFilter {
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<SortFindManyNotificationInput>;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserReconheceCampaigns {
  addedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  internalCampaignId: Scalars['String'];
  points: Scalars['Float'];
};

export class UserReconheceHistory {
  _id: Scalars['String'];
  externalExtractId: Scalars['String'];
  externalRequestNumber: Scalars['Float'];
  /** enum(credit|reserve|debit|release|chargeback|reverse|expired|disabledExpired|manualCredit|manualRevertReserved|pending) */
  op: Array<Scalars['String']>;
  time: Scalars['DateTime'];
  value: Scalars['Float'];
};

export class UserTim {
  CPF?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  acceptedTerms?: Maybe<Array<UserAcceptedTerms>>;
  avatar?: Maybe<File>;
  avatarId?: Maybe<Scalars['String']>;
  blockedCommenting?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  gamefications?: Maybe<UserGamefication>;
  jobTitle?: Maybe<JobTitle>;
  kpi?: Maybe<UserKpi>;
  matricula?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notifications>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<RoleGroup>;
  token?: Maybe<LoginToken>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
};

export enum ValidityEnum {
  Any = 'ANY',
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  Future = 'FUTURE'
}

export class UpdateOfferGroupCommandmentsInput {
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DefaultMessageFragment = { message: string, status: number };

export type AuthTypeFragment = { token: string, user: { createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null } };

export type UserTimFragment = { CPF?: string | null, _id?: string | null, email?: string | null, name?: string | null, token?: { token?: string | null } | null };

export type UserLoginFragment = { user?: { CPF?: string | null, _id?: string | null, email?: string | null, name?: string | null, token?: { token?: string | null } | null } | null };

export type UserFragment = { createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null };

export type UserPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null }> | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { CurrentUserTim: { user?: { CPF?: string | null, _id?: string | null, email?: string | null, name?: string | null, token?: { token?: string | null } | null } | null } };

export type UsersQueryVariables = Exact<{
  input: SearchUserInput;
}>;


export type UsersQuery = { users: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null }> | null } };

export type LoginMutationVariables = Exact<{
  data: UserLoginInput;
}>;


export type LoginMutation = { LoginWithPassword: { CPF?: string | null, _id?: string | null, email?: string | null, name?: string | null, token?: { token?: string | null } | null } };

export type RedirectAdminTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RedirectAdminTokenMutation = { RedirectAdminToken: { CPF?: string | null, _id?: string | null, email?: string | null, name?: string | null, token?: { token?: string | null } | null } };

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { changePassword: { message: string, status: number } };

export type ForgotPasswordMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null } };

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { createdAt: any, deletedAt?: any | null, email: string, id: string, jobRole?: string | null, name: string, pathImage?: string | null, phone?: string | null, phonePrefix?: string | null, profile: string, resetPasswordExpiration?: any | null, updatedAt: any, access?: Array<{ createdAt: any, id: string, ip: string }> | null } };

export type DeleteUserMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type DeleteUserMutation = { deleteUser: { message: string, status: number } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  access {
    createdAt
    id
    ip
  }
  createdAt
  deletedAt
  email
  id
  jobRole
  name
  pathImage
  phone
  phonePrefix
  profile
  resetPasswordExpiration
  updatedAt
}
    `;
export const AuthTypeFragmentDoc = gql`
    fragment AuthType on AuthType {
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const UserTimFragmentDoc = gql`
    fragment UserTim on UserTim {
  CPF
  _id
  email
  name
  token {
    token
  }
}
    `;
export const UserLoginFragmentDoc = gql`
    fragment UserLogin on UserLogin {
  user {
    ...UserTim
  }
}
    ${UserTimFragmentDoc}`;
export const UserPageFragmentDoc = gql`
    fragment UserPage on UserPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const CurrentUserDocument = gql`
    query CurrentUser {
  CurrentUserTim {
    ...UserLogin
  }
}
    ${UserLoginFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($input: SearchUserInput!) {
  users(data: $input) {
    ...UserPage
  }
}
    ${UserPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($data: UserLoginInput!) {
  LoginWithPassword(data: $data) {
    ...UserTim
  }
}
    ${UserTimFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RedirectAdminTokenDocument = gql`
    mutation RedirectAdminToken($token: String!) {
  RedirectAdminToken(token: $token) {
    ...UserTim
  }
}
    ${UserTimFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RedirectAdminTokenGQL extends Apollo.Mutation<RedirectAdminTokenMutation, RedirectAdminTokenMutationVariables> {
    document = RedirectAdminTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: ChangePasswordInput!) {
  changePassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: String!) {
  forgotPassword(email: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation resetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($data: String!) {
  deleteUser(id: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }